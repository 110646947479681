
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

body {
  margin: 0;
}
header {
  width: 100%;
  max-width: 1863px;
  background-color: #C4C4C4;
  padding: 20px;
  /*height: 50px;*/
}
.header {
  display: flex;
  justify-content: space-around;

}
header img {
  cursor: pointer;
  width: 18px;
  height: 20px;
  margin: 32px;
}
.language {
  display: flex;
}
.card-header img {
  width: 376px;
  height: 170px;
  border-radius: 16px;
}
.franch-flag {
  transform: rotate(90deg);
}
.language img {
  width: 30px;
  height: 30px;
  margin-right: 2px;
}
.dropbtn {
  background-color: lightgray;
  color: white;
  /*padding: 16px;*/
  font-size: 16px;
  border: none;
}

.dropdown {
  display: flex;
  /*margin: 0 1500px;*/
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 0 30px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}
.header-ul {
  display: flex;
  /*margin-left: 240px;*/
}
header .header-ul li {
  display: flex;
  color: white;
  text-decoration: none;
  font-family: Roboto, serif;
}
li {
  text-decoration: none;
  margin: 0 36px;
}
main .title {
  display: flex;
  justify-content: center;
  font-size: 32px;
  font-family: Roboto, serif;
}
.main-img {
  display: flex;
  /* justify-content: center; */
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
  justify-content: center;

}

.img-link {
  display: flex;
  justify-content: center;
  width: 616px;
  margin: 15px;
  height: 606px;

}
.img-left {
  width: 616px;
  margin: 15px;
  height: 606px;


}
.img-right {
  width: 616px;
  margin: 15px;
  height: 606px;
}
.img-left p {
  text-align: center;
  margin-top: 300px;
  font-size: 26px;
  color: #131313;
  font-family: Roboto, serif;

}
.img-right p {
  text-align: center;
  margin-top: 300px;
  font-size: 26px;
  color: #131313;
  font-family: Roboto, serif;
}
.audio {
  width: 1264px;
  height: 125px;
  background-color: #C4C4C4;
  display: flex;
  justify-content: start;
  font-size: 26px;

}
.audio p {
  margin: 55px 10px;
  color: #131313;
  font-family: Roboto, serif;
}
main .general-div {
  display: flex;
  justify-content: center;
}

.text {
  width: 936px;
  font-size: 20px;
  line-height: 32px;
  color: #131313;
  font-family: Roboto, serif;

}
main .text-div {
  display: flex;
  margin-left: 320px;
}
.preview, .next {
  cursor: pointer;
}
.prev-next {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1260px;
  margin: 80px 319px;
}


.preview span a, .next span a {
  display: flex;
}
span p {
  margin: 3px 17px;
  font-family: Roboto, serif;
}
span img {
  height: 24px;
}

footer {
  margin: 150px 750px;
}
.footer-ul {
  display: flex;
}
footer .footer-ul li {
  display: flex;
  color: #131313;
  text-decoration: none;
  font-family: Roboto, serif;
}